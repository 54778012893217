


export class BrowserAppEvaluationToolInfo {

  public version = '2024.6.14.1';

  constructor() {
  }

}
